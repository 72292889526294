<template>
  <v-app id="screen">
    <router-view></router-view>
  </v-app>
</template>

<script>
  export default {
    components: {},
  };
</script>

<style scoped lang="scss"></style>
