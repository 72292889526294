import store from '../store/index';
import { getInstance } from './index';
export const nullStoreGuard = (to, from, next) => {
  const authService = getInstance();
  const fn = () => {
    if (
      store.getters.storeId !== null ||
      authService.isStoreOwner ||
      authService.isStoreAdmin ||
      authService.isStoreUser
    ) {
      return next();
    } else {
      return next({ name: 'manage-stores' });
    }
  };
  // if not authenticated, redirect to unauthorized
  if (!authService.isAuthenticated) {
    return next('/unauthorized');
  }
  // authed && access token retrieved
  else if (authService.accessTokenClaims) {
    return fn();
  }

  // Watch for the accessTokenClaims to be defined to check role
  authService.$watch('accessTokenClaims', (atc) => {
    if (atc !== null) {
      return fn();
    }
  });
};
