import Vue from 'vue';
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';

import { mapActions } from 'vuex';

import store from '../store';
/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    store,
    data() {
      return {
        authLoading: true,
        isAuthenticated: false,
        isDisabled: false,
        user: {},
        accessToken: null,
        accessTokenClaims: null,
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    methods: {
      ...mapActions(['setStoreId', 'setPortalId']),

      async setData(authResult) {
        const resp = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/stores/check`,
          {
            headers: {
              Authorization: `Bearer ${authResult.accessToken}`,
            },
          }
        );
        if (resp.ok) {
          this.error = '';
          this.user = authResult.idTokenPayload;
          this.isAuthenticated = true;
          this.accessToken = authResult.accessToken;
          this.accessTokenClaims = jwtDecode(this.accessToken);
          this.authLoading = false;
          return;
        }
        if (resp.status === 403) {
          this.isDisabled = true;
          this.clearData('Your account is disabled');
        }
      },
      clearData(err) {
        this.error = err;
        this.isAuthenticated = false;
        this.user = {};
        this.accessToken = null;
        this.accessTokenClaims = null;
        this.authLoading = false;
      },

      /** Authenticates the user using the credential */
      loginWithCredentials(username, password) {
        return new Promise((resolve, reject) => {
          this.auth0Client.login(
            {
              realm: process.env.VUE_APP_REALM,
              username: username,
              password: password,
            },
            (err, authResult) => {
              if (err) {
                this.clearData(err);
                reject(err);
                return;
              }
              this.setData(authResult);
              resolve(authResult);
            }
          );
        });
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently() {
        return new Promise((resolve, reject) => {
          this.auth0Client.checkSession({}, (err, authResult) => {
            if (err) {
              reject(err);
            } else {
              resolve(authResult.accessToken);
            }
          });
        });
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      this.auth0Client = new auth0.WebAuth({
        domain: options.domain,
        clientID: options.clientId,
        audience: options.audience,
        redirectUri: redirectUri,
        responseType: 'token id_token',
        scope:
          'openid profile email address phone read:users read:user_idp_tokens update:users read:users_app_metadata',
      });

      if (window.location.hash.includes('access_token=')) {
        this.auth0Client.parseHash(async (err, authResult) => {
          if (err) {
            this.clearData(err);
            return;
          }
          await this.setData(authResult);
          onRedirectCallback(authResult, this);
        });
      } else {
        this.auth0Client.checkSession({}, async (err, authResult) => {
          if (err) {
            this.clearData(err);
            return;
          }
          if (authResult && authResult.accessToken && authResult.idToken) {
            await this.setData(authResult);
            onRedirectCallback(authResult, this);
          }
        });
      }
    },
    computed: {
      isGlobalAdmin() {
        if (!this.accessTokenClaims) {
          return false;
        }
        return this.accessTokenClaims[
          `${process.env.VUE_APP_AUDIENCE}/roles`
        ].includes('mtmproGlobalAdmin');
      },
      isStoreOwner() {
        if (!this.accessTokenClaims) {
          return false;
        }
        return this.accessTokenClaims[
          `${process.env.VUE_APP_AUDIENCE}/roles`
        ].includes('mtmproStoreOwner');
      },
      isStoreAdmin() {
        if (!this.accessTokenClaims) {
          return false;
        }
        return this.accessTokenClaims[
          `${process.env.VUE_APP_AUDIENCE}/roles`
        ].includes('mtmproStoreAdmin');
      },
      isStoreUser() {
        if (!this.accessTokenClaims) {
          return false;
        }
        return this.accessTokenClaims[
          `${process.env.VUE_APP_AUDIENCE}/roles`
        ].includes('mtmproStoreUser');
      },
      isManufAdmin() {
        if (!this.accessTokenClaims) {
          return false;
        }
        return this.accessTokenClaims[
          `${process.env.VUE_APP_AUDIENCE}/roles`
        ].includes('mtmproManufAdmin');
      },
      isManufRep() {
        if (!this.accessTokenClaims) {
          return false;
        }
        return this.accessTokenClaims[
          `${process.env.VUE_APP_AUDIENCE}/roles`
        ].includes('mtmproManufRep');
      },
    },
    watch: {
      isStoreOwner(val) {
        if (val) {
          this.setStoreId(
            this.accessTokenClaims[`${process.env.VUE_APP_AUDIENCE}/storeId`]
          );
        }
      },
      isStoreAdmin(val) {
        if (val) {
          this.setStoreId(
            this.accessTokenClaims[`${process.env.VUE_APP_AUDIENCE}/storeId`]
          );
        }
      },
      isStoreUser(val) {
        if (val) {
          this.setStoreId(
            this.accessTokenClaims[`${process.env.VUE_APP_AUDIENCE}/storeId`]
          );
        }
      },
      isManufAdmin(val) {
        if (val) {
          this.setPortalId(
            this.accessTokenClaims[`${process.env.VUE_APP_AUDIENCE}/portalId`]
          );
        }
      },
      isManufRep(val) {
        if (val) {
          this.setPortalId(
            this.accessTokenClaims[`${process.env.VUE_APP_AUDIENCE}/portalId`]
          );
        }
      },
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
