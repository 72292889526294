import { getInstance } from './index';

export const storeAdminGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is global admin, continue with the route
    if (
      authService.isGlobalAdmin ||
      authService.isStoreOwner ||
      authService.isStoreAdmin ||
      authService.isStoreUser
    ) {
      return next();
    }

    // Otherwise, redirect to unauthorized
    next('/unauthorized');
  };

  // if not authenticated, redirect to unauthorized
  if (!authService.isAuthenticated) {
    return next('/unauthorized');
  }
  // authed && access token retrieved
  else if (authService.accessTokenClaims) {
    return fn();
  }

  // Watch for the accessTokenClaims to be defined to check role
  authService.$watch('accessTokenClaims', (atc) => {
    if (atc !== null) {
      return fn();
    }
  });
};
