import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from "../views/Home.vue";
// import Landing from "../views/Landing.vue";
// import Profile from "../views/Profile.vue";
// import StoreManagement from "../views/StoreManagement.vue";
// import Unauthorized from "../views/Unauthorized.vue";
import { authGuard } from '../auth/authGuard';
import { adminGuard } from '../auth/adminGuard';
import { multiguard } from '../auth/multiguard';
// import { checkLoginGuard } from "../auth/checkLoginGuard";
Vue.use(VueRouter);
import { globalRoutes } from './globalRoutes';
import { storeRoutes } from './storeRoutes';
import { manufacturerRoutes } from './manufacturerRoutes';
const localRoutes = [
  {
    path: '/',
    name: 'home',
    // redirect: "/home",
    // component: Landing,
    // beforeEnter: checkLoginGuard
    beforeEnter: authGuard,
    // redirect: to => {

    // }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    beforeEnter: authGuard,
  },

  {
    path: '/store/:id',
    name: 'StoreManagement',
    component: () => import('../views/StoreManagement.vue'),
    beforeEnter: multiguard([authGuard, adminGuard]),
    props: true,
  },

  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('../views/Unauthorized.vue'),
  },
];

const routes = [
  ...localRoutes,
  ...globalRoutes,
  ...manufacturerRoutes,
  ...storeRoutes,
];
// import InternalApp from "@/views/InternalApp.vue";
// import PdfGen from "@/views/PdfGen.vue";
// import { supportSiteGuard } from "@/utils/supportSite";
// import SupportSiteComponent from "./SupportSiteComponent.vue";
const newR = [
  {
    path: '/pdf-gen',
    component: () => import('@/views/PdfGen.vue'),
  },
  {
    path: '/pdf-gen-ticket',
    component: () => import('@/views/PdfGenForTicket.vue'),
  },
  {
    path: '/confirm',
    component: () => import('@/views/vendor/FabricPoConfirm.vue'),
  },
  {
    path: '/onboarding',
    component: () => import('@/views/Onboarding.vue'),
  },
  {
    path: '/signin',
    component: () => import('@/views/SignIn.vue'),
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/',
    component: () => import('@/views/apps/InternalApp.vue'),
    children: routes,
  },
  // {
  //   path: "/support-site",
  //   beforeEnter: multiguard([authGuard, supportSiteGuard]),
  // },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: newR,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
