import { manufacturerAdminGuard } from '../auth/manufacturerAdminGuard';
import { authGuard } from '../auth/authGuard';
import { nullManufacturerGuard } from '../auth/nullManufacturerGuard';
import { multiguard } from '../auth/multiguard';
export const manufacturerRoutes = [
  {
    path: '/m/dashboard',
    name: 'dashboard-manufacturer',
    component: () => import('../views/manufacturer/Dashboard.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
  {
    path: '/m/processing',
    name: 'home-manufacturer',
    component: () => import('../views/manufacturer/Processing.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
  {
    path: '/m/accounts',
    name: 'accounts',
    component: () => import('../views/manufacturer/Accounts.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
  {
    path: '/m/shipments',
    name: 'manu-shipments',
    component: () => import('../views/manufacturer/Shipments.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
  {
    path: '/m/settings',
    name: 'settings',
    component: () => import('../views/manufacturer/MfrSettings.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
  {
    path: '/m/archive',
    name: 'archive',
    component: () => import('../views/manufacturer/Archive.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
  {
    path: '/m/accounts/:id',
    name: 'account-detail',
    props: true,
    component: () => import('../views/manufacturer/AccountsDetail.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
  {
    path: '/m/:path?',
    name: '/home-manufacturer-redirect',
    component: () => import('../views/manufacturer/Processing.vue'),
    beforeEnter: multiguard([
      authGuard,
      manufacturerAdminGuard,
      nullManufacturerGuard,
    ]),
  },
];
