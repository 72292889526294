import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { http } from './plugins/http';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import VoerroTagsInput from '@voerro/vue-tagsinput';
import 'tiptap-vuetify/dist/main.css';
// import "vuetify/dist/vuetify.min.css";
import './scss/custom-reset.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@voerro/vue-tagsinput/dist/style.css';
import VueHtmlToPaper from 'vue-html-to-paper';
import './scss/style.scss';
import jwtDecode from 'jwt-decode';
import VueApexCharts from 'vue-apexcharts';
import DateSpan from '@/components/utility/DateSpan.vue';

// Register DateSpan as a global component
Vue.component('DateSpan', DateSpan);

Vue.use(VueHtmlToPaper);
Vue.component('tags-input', VoerroTagsInput);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md',
});

import { Auth0Plugin } from './auth';
Vue.prototype.$http = http;

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_DOMAIN,
  clientId: process.env.VUE_APP_CLIENT_ID,
  audience: process.env.VUE_APP_AUDIENCE,
  onRedirectCallback: (authResult: any, $auth: any) => {
    const appState = authResult.appState;
    const targetUrl =
      (appState && appState.targetUrl) || window.location.pathname;
    // if trying to nav to support site route, nav to route

    if (targetUrl.includes('support-site')) {
      return router.push(targetUrl);
    }
    // if authed & not going to support site, determine whether user
    // should be routed to global dash or store customers
    else if ($auth.isAuthenticated) {
      if ($auth.isGlobalAdmin) {
        if (targetUrl === '/') {
          return router.push({ name: 'manage-stores' });
        }
      } else if (
        $auth.isStoreOwner ||
        $auth.isStoreAdmin ||
        $auth.isStoreUser
      ) {
        if (targetUrl === '/') {
          return router.push({ name: 'home-store' });
        }
      } else if ($auth.isManufAdmin || $auth.isManufRep) {
        if (targetUrl === '/') {
          return router.push({ name: 'dashboard-manufacturer' });
        }
      }
    }
    // if no appstate, do nothing
  },
});
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  data() {
    return {
      failureCount: 0,
    };
  },
  created() {
    document.title = 'MTMPRO';
    this.$http.interceptors.request.use((config) => {
      config.headers['Authorization'] = 'Bearer ' + this.$auth.accessToken;
      return config;
    });
    this.$http.interceptors.response.use(
      (response) => {
        this.failureCount = 0;
        return response;
      },
      async (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401 && this.$auth.isAuthenticated) {
          if (this.failureCount >= 3) {
            window.location.reload();
          }
          this.$auth.accessToken = await this.$auth.getTokenSilently();
          this.$auth.accessTokenClaims = jwtDecode(this.$auth.accessToken);
          error.config.headers['Authorization'] =
            'Bearer ' + this.$auth.accessToken;
          error.config.baseURL = undefined;
          this.failureCount += 1;
          return Promise.resolve(this.$http.request(error.config));
        }
        this.failureCount = 0;
        if (status === 403 && this.$auth.isAuthenticated) {
          return this.$auth.logout();
        }
        return Promise.reject(error);
      }
    );
    // this.http.interceptors.push((request, next)  => {
    //   request.headers['Authorization'] = "Bearer " + this.$auth.accessToken;
    //   next(response => response,
    //     async error => {
    //       const status = error.response ? error.response.status : null;
    //       // if unauth response but user is authenticated, refresh token and retry call
    //       if (status === 401 && this.$auth.isAuthenticated) {
    //         // refresh token
    //         this.$auth.accessToken = await this.$auth.getTokenSilently();
    //         this.$auth.accessTokenClaims = jwtDecode(this.$auth.accessToken);
    //         error.config.headers["Authorization"] =
    //           "Bearer " + this.$auth.accessToken;
    //         error.config.baseURL = undefined;
    //         return Promise.resolve(this.$http.request(error.config));
    //       }
    //       return Promise.reject(error);
    //     })
    // });
  },
  methods: {
    openSnackbar(obj) {
      this.$children[0].$children[0].$children[0].openSnackbar(obj);
    },
    removeSnackbar(obj) {
      this.$children[0].$children[0].$children[0].removeSnackbar(obj);
    },
    openPrompt(obj) {
      this.$children[0].$children[0].$children[0].openPrompt(obj);
    },
  },
}).$mount('#app');
