export const DATE_FORMATS = {
  US: 'US',
  European: 'European',
  ISO: 'ISO',
};

export const STATUS = {
  INITIAL: 'initial',
  FAILURE: 'failure',
  SUCCESS: 'success',
  LOADING: 'loading',
  NOT_AVAILABLE: 'not_available',
};

export const INITIAL_ORDER_SUBMITTING_STATUS = {
  lockingGarmentData: STATUS.INITIAL,
  sendingToManufacturerPortal: STATUS.NOT_AVAILABLE,
  compilingAndEmailing: STATUS.INITIAL,
  compilingAndEmailingSuccessCount: 0,
  compilingAndEmailingFailureCount: 0,
  updatingStatuses: STATUS.INITIAL,
  addingFabrics: STATUS.INITIAL,
  checkingSystem: STATUS.INITIAL,
};
