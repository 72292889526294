// import Dashboard from "../views/global/Dashboard.vue";
// import Stores from "@/views/global/Stores.vue";
// import ManageManufacturers from "@/views/global/ManageManufacturers.vue";
// import ManageGarmentTypes from "@/views/global/ManageGarmentTypes.vue";
// import ManagedForms from "@/views/global/ManagedForms.vue";
import { globalAdminGuard } from '../auth/globalAdminGuard';
import { authGuard } from '../auth/authGuard';
import { resetStoreIdGuard } from '../auth/resetStoreIdGuard';
import { resetPortalIdGuard } from '../auth/resetPortalIdGuard';
import { multiguard } from '../auth/multiguard';
import store from '../store/index';
export const globalRoutes = [
  // {
  //   path: "/global-dashboard",
  //   name: "global-dashboard",
  //   component: Dashboard,
  //   beforeEnter: multiguard([authGuard, globalAdminGuard, resetStoreIdGuard])
  // },
  {
    path: '/stores',
    name: 'manage-stores',
    component: () => import('@/views/global/Stores.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
  },
  {
    path: '/manufacturers',
    name: 'manufacturers',
    component: () => import('@/views/global/ManageManufacturers.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
  },
  {
    path: '/manufacturers/:id',
    name: 'manufacturer',
    component: () => import('@/views/global/ManageManufacturer.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
    alias: ['/manufacturers/:id/edit', '/manufacturers/:id/edit/field'],
  },
  {
    path: '/garment-types',
    name: 'garment-types',
    component: () => import('@/views/global/ManageGarmentTypes.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
    alias: ['/garment-types/edit', '/garment-types/edit/field'],
  },
  {
    path: '/managed-forms',
    name: 'managed-forms',
    component: () => import('@/views/global/ManagedForms.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
    alias: ['/managed-forms/edit', '/managed-forms/edit/field'],
  },
  {
    path: '/fabric-sources',
    name: 'fabric-sources',
    component: () => import('@/views/global/FabricSources.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/global/YardageFabrics.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
  },
  {
    path: '/alterations',
    name: 'alterations',
    component: () => import('@/views/global/ManageAlterations.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
  },
  {
    path: '/portals',
    name: 'manage-portals',
    component: () => import('@/views/global/ManageManufacturerPortals.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
  },
  {
    path: '/deploy',
    name: 'manage-deploy',
    component: () => import('@/views/global/ManageDeployHistory.vue'),
    beforeEnter: multiguard([
      authGuard,
      globalAdminGuard,
      resetStoreIdGuard,
      resetPortalIdGuard,
    ]),
  },
];
